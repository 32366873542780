import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';


function NotFoundPage() {
    return (
        <Layout>
            <Helmet>
                <title>404 | Optosweden AB</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Vi gör din data till värdefull och användbar information. Vår teknik med AI och maskininlärning tar din dokumenthantering till en ny nivå. Vi har lösningar som effektiviserar er hantering av fakturor, formulär och andra typer av dokument." />
                <meta name="keywords" content="dokumentflöden" />
                <meta property="og:title" content="Optosweden AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Vi digitaliserar dokumentflöden!" />
                <meta property="og:image" content="" />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="http://www.optosweden.se/404" />
                <link rel="cannonical" href="http://www.optosweden.se/404" />
            </Helmet>
            
            <div className="h-screen py-32 bg-gray-100">
                <div class="container mx-auto px-2">
                    <h1 className="text-4xl  md:text-5xl lg:text-6xl xl:text-7xl font-extrabold text-center text-blue-custome">404 – Sidan kan inte hittas</h1>
                    <p className="text-lg md:text-2xl m-7 md:w-3/4 md:m-auto md:my-10 text-center text-gray-500" >Sidan du letar efter kunde inte hittas! Tryck på knappen nedan för att komma tillbaka på rätt spår.</p>
                    <div className="w-100 flex justify-center">
                        <Link to="/" className="px-12 py-2 rounded-full ml-0 lg:ml-6 text-white bg-blue-custome shadow-md hover:opacity-90">Hem</Link>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default NotFoundPage;